/**
 *
 * Tabs
 *
 */

import React from 'react';
import { Tabs as CustomTabs } from 'antd';

import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
function Tabs({ ...rest }) {
  return <CustomTabs {...rest} />;
}

Tabs.propTypes = {};

export default Tabs;
