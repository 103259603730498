/**
 *
 * Component: CustomCell
 * Date: 28/8/2022
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'components/common';
import { TEXT_TOOLTIP } from 'utils/commonConstants';

function CustomCell({ toolTipTitle, ...rest }) {
  return toolTipTitle ? (
    <Tooltip
      title={toolTipTitle}
      type={TEXT_TOOLTIP}
      overlayClassName="customCellTooltip"
      destroyTooltipOnHide={{
        keepParent: false,
      }}
    >
      <td {...rest} />
    </Tooltip>
  ) : (
    <td {...rest} />
  );
}

CustomCell.propTypes = {
  toolTipTitle: PropTypes.string,
  rest: PropTypes.any,
};

CustomCell.propTypes = {};

export default CustomCell;
