/**
 *
 * Checkbox
 *
 */

import React from 'react';
import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Checkbox as CustomCheckbox } from 'antd';
function Checkbox({ ...rest }) {
  return <CustomCheckbox {...rest} />;
}
Checkbox.propTypes = {};

export default Checkbox;
