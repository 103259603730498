/**
 *
 * Component: Player
 * Date: 4/4/2022
 *
 */

import PropTypes from 'prop-types';
import React, { useState, forwardRef } from 'react';

import AudioPlayer from 'components/AudioPlayer';
import TranscriptPlayer from 'components/TranscriptPlayer';

const Player = forwardRef(({ ...rest }, HowlerRef) => {
  const [audioBarLocations, setAudioBarLocations] = useState([]);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [isHowlerLoading, setIsHowlerLoading] = useState(true);

  return (
    <>
      <AudioPlayer
        ref={HowlerRef}
        isHowlerLoading={isHowlerLoading}
        audioCurrentTime={audioCurrentTime}
        setAudioCurrentTime={setAudioCurrentTime}
        setIsHowlerLoading={setIsHowlerLoading}
        audioBarLocations={audioBarLocations}
        {...rest}
      />

      <TranscriptPlayer
        ref={HowlerRef}
        isHowlerLoading={isHowlerLoading}
        audioCurrentTime={audioCurrentTime}
        setAudioCurrentTime={setAudioCurrentTime}
        setAudioBarLocations={setAudioBarLocations}
        {...rest}
      />
    </>
  );
});

export default Player;

Player.propTypes = {
  call: PropTypes.object,
  searchText: PropTypes.string,
  toggleTranscriptView: PropTypes.func,
  HowlerPlayId: PropTypes.object,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  setShowExtraMetaData: PropTypes.func,
  transcriptView: PropTypes.bool,
  loading: PropTypes.bool,
  dimensions: PropTypes.object,
  showMore: PropTypes.bool,
  showExtraMetaData: PropTypes.bool,
  setShowMore: PropTypes.object,
  setShowExpandTranscript: PropTypes.object,
  showExpandTranscript: PropTypes.bool,
};
