/**
 *
 * Radio
 *
 */

import React from 'react';
import { Radio as CustomRadio } from 'antd';

import './style.less';

function Radio({ ...rest }) {
  return <CustomRadio {...rest} />;
}

Radio.propTypes = {};

export default Radio;
const RadioButton = CustomRadio.Button;
export { RadioButton };
