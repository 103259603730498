/**
 *
 * FileUploader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import './style.less';

function FileUploader({ uploadButton, acceptFileTypes, ...rest }) {
  let acceptType = acceptFileTypes;
  if (typeof acceptFileTypes === 'object') {
    acceptType = acceptFileTypes.join(',');
  }
  return (
    <Upload {...rest} accept={acceptType}>
      {uploadButton}
    </Upload>
  );
}

FileUploader.propTypes = {
  uploadButton: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  acceptFileTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default FileUploader;
