import {
  getRequest,
  postRequest,
  deleteRequest,
  patchRequest,
  API,
} from 'utils/request';

const fetchSingleCallNotes = async callId => {
  if (!callId) return [];
  const res = await getRequest(`${API.notes}/${callId}`);
  // injected Notes value (delete after test).
  // res.data.data.notes[0].mentionedEmails = [
  //   'dev-role@prodigaltech.com',
  //   'dev@prodigaltech.com',
  // ];
  // res.data.data.notes[0].mentionedScorecards = [
  //   { name: 'test-grade', _id: '9c7cacd5-81f9-40d3-a80b-527be58be294' },
  // ];
  // res.data.data.notes[0].mentionedScorecards = [
  //   {
  //     _id: '214ce583-acf1-4548-b8e0-f524aea98836',
  //     name: 'S Internal Scorecard',
  //   },
  // ];
  // res.data.data.notes[1].nonSerializedText = testObj;
  // ////////////
  return res.data.data.notes;
};

const postNewSingleCallNote = async ({ requestParams, callId }) => {
  const res = await postRequest(`${API.note}/${callId}`, requestParams);
  return res.data;
};

const editSingleCallNote = async ({ requestParams, callId, noteId }) => {
  const res = await patchRequest(
    `${API.note}/${callId}?noteId=${noteId}`,
    requestParams,
  );
  return res.data;
};

const deleteSingleCallNote = async ({ requestParams, callId, noteId }) => {
  const res = await deleteRequest(
    `${API.note}/${callId}?noteId=${noteId}`,
    requestParams,
  );
  return res.data;
};

export {
  fetchSingleCallNotes,
  postNewSingleCallNote,
  editSingleCallNote,
  deleteSingleCallNote,
};
