import { getRequest, API } from 'utils/request';

const fetchAppConfig = async () => {
  const res = await getRequest(API.getAppConfig);
  return res.data;
};
const fetchInboxCounter = async () => {
  const res = await getRequest(API.inboxCount);
  return res.data;
};

export { fetchAppConfig, fetchInboxCounter };
