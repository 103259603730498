/**
 *
 * Avatar
 *
 */

import React from 'react';
import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Avatar as CustomAvatar } from 'antd';
function Avatar({ ...rest }) {
  return <CustomAvatar {...rest} data-testid="Avatar-cy" />;
}

Avatar.propTypes = {};

export default Avatar;
