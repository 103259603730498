/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import languageProviderReducer from 'containers/LanguageProvider/reducer';
import history from 'utils/history';
import callSearchReducer from 'pages/CallSearch/reducer';
import smartReportReducer from 'pages/SmartReport/reducer';
import aggregatedScorecardReducer from 'pages/AggregatedScorecard/reducer';
import reviewScorecardListReducer from 'pages/ReviewScorecardList/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    router: connectRouter(history),
    reviewScorecardList: reviewScorecardListReducer,
    aggregatedScorecard: aggregatedScorecardReducer,
    callSearch: callSearchReducer,
    smartReport: smartReportReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
