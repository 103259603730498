/**
 *
 * Card
 *
 */

import React from 'react';
import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Card as CustomCard } from 'antd';
function Card({ ...rest }) {
  return <CustomCard {...rest} />;
}

Card.propTypes = {};

export default Card;
