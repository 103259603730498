/**
 *
 * List
 *
 */

import React from 'react';
import { List as CustomList } from 'antd';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

import './style.less';

function List({ ...rest }) {
  return <CustomList {...rest} />;
}

List.propTypes = {};

export default CustomList;
