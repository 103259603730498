/**
 *
 * Tag
 *
 */

import React from 'react';
import { Tag as CustomTag } from 'antd';
import PropTypes from 'prop-types';
import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function Tag({ text, ...rest }) {
  return <CustomTag {...rest}>{text || rest.children}</CustomTag>;
}

Tag.propTypes = {
  text: PropTypes.string,
};

export default Tag;
