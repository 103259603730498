/**
 *
 * Alert
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import './style.less';
// import styled from 'styled-components';
import { Alert as CustomAlert } from 'antd';

function Alert({ text, type, closable, ...rest }) {
  return (
    <CustomAlert
      {...rest}
      message={text}
      type={type}
      closable={closable}
      data-testid="Alert-cy"
    />
  );
}

Alert.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  closable: PropTypes.bool,
};

export default Alert;
