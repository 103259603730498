/**
 *
 * Component: Drawer
 * Date: 7/9/2021
 *
 */

import React from 'react';
import { Drawer as CustomDrawer } from 'antd';

function Drawer({ ...rest }) {
  return <CustomDrawer data-testid="drawer-cy" {...rest} />;
}

Drawer.propTypes = {};

export default Drawer;
