/**
 *
 * EmptyState
 *
 */

import React from 'react';
import { Result } from 'antd';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function EmptyState({
  image,
  title,
  message,
  extra,
  icon,
  iconWidth,
  iconHeight,
  ...rest
}) {
  return (
    <Result
      {...rest}
      title={title}
      subTitle={message}
      extra={extra}
      icon={
        <Icon
          style={{
            width: iconWidth || '255px',
            height: iconHeight || '200px',
            objectFit: 'contain',
          }}
          type={icon || 'emptyState'}
          showPointer={false}
        />
      }
    />
  );
}

EmptyState.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.node,
  image: PropTypes.node,
  icon: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
};

export default EmptyState;
