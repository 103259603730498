/**
 *
 * Component: QuestionnaireSkeletonLoader
 * Date: 16/9/2020
 *
 */

import React from 'react';

import SkeletonLoader from 'components/SkeletonLoader';
import { Card, Radio, Text } from 'components/common';

import styles from './style.css';

function QuestionnaireSkeletonLoader({ children }) {
  const getLoader = () => (
    <div>
      <Card className={styles.section}>
        <div className={styles.title}>
          <SkeletonLoader type="line" />
        </div>
        <div className={styles.description}>
          <SkeletonLoader type="line" />
        </div>
      </Card>
      {['1', '2', '3', '4'].map(question => (
        <Card key={question} className={styles.question}>
          <div className={styles.questionText}>
            <Text type="paragraphLarge" text={`${question}. `} />
            <SkeletonLoader type="line" />
          </div>
          <div className={styles.options}>
            <div className={styles.radioOption}>
              <Radio disabled />
              <div className={styles.option}>
                <SkeletonLoader type="line" />
              </div>
            </div>
            <div className={styles.radioOption}>
              <Radio disabled />
              <div className={styles.option}>
                <SkeletonLoader type="line" />
              </div>
            </div>
            <div className={styles.radioOption}>
              <Radio disabled />
              <div className={styles.option}>
                <SkeletonLoader type="line" />
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );

  const checkChildren = () => {
    if (Array.isArray(children))
      return children.every(element => element === undefined)
        ? getLoader()
        : children;
    return children;
  };

  return children ? checkChildren() : getLoader();
}

QuestionnaireSkeletonLoader.propTypes = {};

export default QuestionnaireSkeletonLoader;
