/**
 *
 * Row
 *
 */

import React from 'react';
import { Row as CustomRow } from 'antd';

import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
function Row({ ...rest }) {
  return <CustomRow {...rest} />;
}

Row.propTypes = {};

export default Row;
