/**
 *
 * Input
 *
 */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input as CustomInput, InputNumber } from 'antd';

import Icon from '../Icon';
import './style.less';

const Input = forwardRef(
  ({ type, backgroundColor, enableAutoLength, ...rest }, ref) => {
    const style = {
      height: rest.height || '43px',
      backgroundColor: backgroundColor || 'inherit',
    };

    const getInputComponent = () => {
      let component = <div data-testid="Input-cy" />;
      switch (type) {
        case 'search':
          component = (
            <CustomInput
              {...rest}
              ref={ref}
              prefix={<Icon type="search" />}
              style={style}
            />
          );
          break;
        case 'password':
          component = (
            <CustomInput.Password {...rest} ref={ref} style={style} />
          );
          break;
        case 'number':
          component = <InputNumber {...rest} ref={ref} style={style} />;
          break;
        case 'text-area':
          component = (
            <div>
              <CustomInput.TextArea {...rest} ref={ref} style={style} />
            </div>
          );
          break;
        default:
          component = <CustomInput {...rest} ref={ref} style={style} />;
          break;
      }
      return component;
    };
    return getInputComponent();
  },
);

Input.propTypes = {
  type: PropTypes.string,
  enableAutoLength: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default Input;
