/**
 *
 * Layout
 *
 */

import React from 'react';
import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Layout as CustomLayout } from 'antd';
function Layout({ ...rest }) {
  return <CustomLayout {...rest} />;
}

Layout.propTypes = {};

export default CustomLayout;
