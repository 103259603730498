/**
 *
 * LoadingIndicator
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { PRODIGAL_LOGO_LIGHT } from 'utils/commonConstants';
import LoadingIcon from 'assets/icons/loading.gif';
import { Text } from 'components/common';
import './style.less';
// import styled from 'styled-components';

/**
 * Simple Loading Indicator, use classes.
 * */
function LoadingIndicator({
  text,
  disableImage = false,
  background = false,
  ...rest
}) {
  return (
    <div style={rest.style} className="main-async-loader">
      {!disableImage && (
        <img
          src={PRODIGAL_LOGO_LIGHT}
          alt="prodigal-logo"
          className="prodigal-logo-light"
        />
      )}
      <div>
        <Text text={text || ''} />
        <div
          className={`loading-icon ${background ? ' loading-background' : ''}`}
        >
          <img src={LoadingIcon} alt="" />
        </div>
      </div>
    </div>
  );
}

LoadingIndicator.propTypes = {
  text: PropTypes.string,
  disableImage: PropTypes.bool,
  background: PropTypes.bool,
};

export default LoadingIndicator;
