/**
 *
 * Slider
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Slider as CustomSlider } from 'antd';
import './style.less';
// import styled from 'styled-components';

function Slider({ min, max, ...rest }) {
  return <CustomSlider {...rest} min={min} max={max} />;
}

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
};

export default Slider;
