/**
 *
 * RadioGroup
 *
 */

import React from 'react';
import { Radio as CustomRadio } from 'antd';
import PropTypes from 'prop-types';

function RadioGroup({ children, ...rest }) {
  return <CustomRadio.Group {...rest}>{children}</CustomRadio.Group>;
}
RadioGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default RadioGroup;
