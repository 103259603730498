/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-undef */
/**
 *
 * Component: SectionBuilder
 * Date: 11/6/2020
 *
 */

import React, {
  useState,
  useCallback,
  useEffect,
  cloneElement,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Card, Text, Input, Button, Select } from 'components/common';
import { insertByIndexInArray } from 'utils/commonFunctions';

import QuestionBuilder from '../QuestionBuilder';
import styles from './style.css';

const option = {
  oText: '',
  oType: 'POSITIVE',
  oScore: 0,
};
const question = {
  name: '',
  maxScore: 0,
  isAutoFail: false,
  isMandatory: false,
  qText: '',
  qType: 'OPTIONS',
  qOptions: [{ ...option }, { ...option }],
};
const section = {
  name: '',
  maxScore: 0,
  sectionType: 'SIMPLE',
  questions: [{ ...question }],
};

const SectionBuilder = ({ initState, onRemove, onChange }) => {
  const [questionList, setQuestionList] = useState([]);
  const [maxScore, setMaxScore] = useState(initState?.maxScore || 0);

  const questionCount = useRef(1);
  const sectionRef = useRef(initState || _.cloneDeep(section));
  const [sectionType, setSectionType] = useState(
    initState?.sectionType || 'SIMPLE',
  );

  /**
   * Calculates the maxStore, from each question, if type is Simple else keeps maxScore as 0 and.
   * reflects upon the onChange prop.
   * */
  const calculateMaxScore = type => {
    if (type === 'SIMPLE') {
      let newMaxScore = 0;
      sectionRef.current.questions.forEach(eachQuestion => {
        newMaxScore += eachQuestion.maxScore;
      });
      setMaxScore(newMaxScore);
      onChange({
        ...sectionRef.current,
        sectionType: type,
        maxScore: newMaxScore,
      });
    } else {
      setMaxScore(0);
      onChange({
        ...sectionRef.current,
        sectionType: type,
        maxScore: 0,
      });
    }
  };

  useEffect(() => {
    if (initState) {
      const list = initState.questions.map((eachQuestion, index) => (
        <QuestionBuilder
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          initData={{ ...eachQuestion }}
          onQuestionChanged={onQuestionChanged(index)}
        />
      ));
      setQuestionList(list);
      sectionRef.current = initState;
    } else {
      setQuestionList([
        <QuestionBuilder key={0} initData={_.cloneDeep(question)} />,
      ]);
    }
  }, [initState]);

  /**
   * Detects question changes, if true than maxScores get updated, based on type of the data provided, sets an automatic value,
   * else, provides the data, than reflects the update in props.
   * */
  const onQuestionChanged = index => data => {
    const changedQuestion = sectionRef.current.questions[index];
    if (data.maxScore !== undefined && sectionType === 'SIMPLE') {
      const hasScoreChanged = changedQuestion.maxScore !== data.maxScore;
      if (hasScoreChanged) {
        sectionRef.current.maxScore += data.maxScore - changedQuestion.maxScore;
        setMaxScore(sectionRef.current.maxScore);
      }
    }
    if (data.type === 'autoFillRule') {
      sectionRef.current.questions[index].autoFillRules = data.value;
    } else {
      sectionRef.current.questions[index] = {
        ...changedQuestion,
        ...data,
      };
    }
    onChange(sectionRef.current);
  };

  /**
   * Deletes the question ref from the QuestionList, and updates the maxScore, reflects back on props.
   * */
  const removeQuestion = useCallback(
    index => () => {
      const newList = [...questionList];
      if (newList.length > 1) {
        newList.splice(index, 1);
        sectionRef.current.maxScore -=
          sectionRef.current.questions[index].maxScore;
        sectionRef.current.questions.splice(index, 1);
        onChange(sectionRef.current);
        setMaxScore(sectionRef.current.maxScore);
        setQuestionList(newList);
      }
    },
    [questionList.length],
  );

  /**
   * Duplicate the Question in the list and add a copy of the question at an immediate index.
   * */
  const duplicateQuestion = useCallback(
    index => () => {
      const { autoFillRules, ...newQuestion } = sectionRef.current.questions[
        index
      ];
      // As We don't want to duplicate autofill rules
      let newData = _.cloneDeep(newQuestion);
      newData = {
        ...newData,
        name: '',
        qText: '',
      };
      sectionRef.current.questions = insertByIndexInArray(
        sectionRef.current.questions,
        index + 1,
        newData,
      );
      sectionRef.current.maxScore += newData.maxScore;
      onChange(sectionRef.current);
      setMaxScore(sectionRef.current.maxScore);
      setQuestionList(list =>
        insertByIndexInArray(
          list,
          index + 1,
          cloneElement(list[index], {
            key: questionCount.current++,
            initData: { ...newData },
            sectionType,
            onRemove: removeQuestion(index),
            onDuplicate: duplicateQuestion(index),
            onQuestionChanged: onQuestionChanged(index),
          }),
        ),
      );
    },
    [questionList.length],
  );

  /**
   * Creates an ref in the Questions List, upon remove, duplicate Questions, or change in sectionType.
   * */
  useEffect(() => {
    setQuestionList(list =>
      list.map((eachQuestion, index) =>
        cloneElement(eachQuestion, {
          onRemove: removeQuestion(index),
          key: questionCount.current++,
          sectionType,
          onDuplicate: duplicateQuestion(index),
          onQuestionChanged: onQuestionChanged(index),
          initData: _.cloneDeep(sectionRef.current?.questions[index]),
        }),
      ),
    );
  }, [removeQuestion, duplicateQuestion, sectionType]);

  /**
   * append the question in the the sectionRef document node, and then append the QuestionBuilder on questions list state.
   * */
  const addQuestion = () => {
    sectionRef.current.questions.push(_.cloneDeep(question));
    onChange(sectionRef.current);
    setQuestionList(list => [
      ...list,
      <QuestionBuilder
        key={questionCount.current++}
        sectionType={sectionType}
        initData={_.cloneDeep(question)}
        onQuestionChanged={onQuestionChanged(list.length)}
        onRemove={removeQuestion(list.length)}
        onDuplicate={duplicateQuestion(list.length)}
      />,
    ]);
  };

  return (
    <Card>
      <div className={styles.sectionHeader}>
        <Input
          placeholder="Section name (40 char limit)"
          defaultValue={initState?.name}
          maxLength={40}
          onChange={e => {
            sectionRef.current.name = e.target.value;
            onChange({ ...sectionRef.current, name: e.target.value });
          }}
        />
        <Select
          defaultValue={sectionRef.current.sectionType || 'SIMPLE'}
          value={sectionType}
          onChange={value => {
            sectionRef.current.sectionType = value;
            calculateMaxScore(value);
            setSectionType(value);
          }}
        >
          <Option key="SIMPLE">Simple</Option>
          <Option key="ALL">All</Option>
        </Select>
      </div>

      <div className={styles.sectionControls}>
        <Button text="Add Question" type="primary" onClick={addQuestion} />
        <Button text="Remove section" onClick={onRemove} />
        <Text text="Max Section Score " />
        <Input
          type="number"
          defaultValue={initState?.maxScore ?? 0}
          value={maxScore}
          disabled={sectionType === 'SIMPLE'}
          onChange={value => {
            sectionRef.current.maxScore = value;
            onChange({ ...sectionRef.current });
            setMaxScore(value);
          }}
        />
      </div>
      {/* <hr className="divider" /> */}
      {questionList}
      <div className={styles.bottomControls}>
        <Button text="Add Question" type="primary" onClick={addQuestion} />
        {/* <Button text="Remove section" onClick={onRemove} /> */}
      </div>
    </Card>
  );
};

SectionBuilder.propTypes = {
  initState: PropTypes.object,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default SectionBuilder;
