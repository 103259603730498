/**
 *
 * Component: Mentions
 * Date: 30/7/2020
 *
 */

import React, { useState, forwardRef, useMemo } from 'react';
import { Mentions as MyMentions } from 'antd';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { fetchMentionsList } from './api';
// import styles from './style.css';

const Mentions = forwardRef(
  ({ autoFocus = false, onChange, defaultValue = '', ...rest }, ref) => {
    const { isLoading, data } = useQuery('mentionsList', fetchMentionsList, {
      staleTime: Infinity,
      cacheTime: Infinity,
    });
    const [value, setValue] = useState(defaultValue);

    const onValueChanged = val => {
      setValue(val);
      if (onChange) onChange(val);
    };

    const mentionsList = useMemo(() => {
      if (!isLoading)
        return data.map((f, i) => (
          <MyMentions.Option
            // eslint-disable-next-line react/no-array-index-key
            key={`${f.email_address}-${i}`}
            value={f.email_address.split('@')[0]}
          >
            {f.email_address}
          </MyMentions.Option>
        ));
      return null;
    }, [data, isLoading]);

    return (
      <MyMentions
        autoFocus={autoFocus}
        ref={ref}
        onChange={onValueChanged}
        value={value}
        {...rest}
      >
        {mentionsList}
      </MyMentions>
    );
  },
);

Mentions.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
};

export default Mentions;
