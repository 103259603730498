/**
 *
 * Component: SingleCallMedia
 * Date: 4/4/2022
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Howl } from 'howler';

import { API } from 'utils/request';
import Player from 'components/Player';
import EventsTab from 'components/EventsTab';
import useLocalStorage from 'utils/hooks/useLocalStorage';

function SingleCallMedia({ call, dimensions, ...rest }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const HowlerRef = useRef();
  const HowlerPlayId = useRef();

  const [userEmail] = useLocalStorage('userEmail');
  const [accessToken] = useLocalStorage('access_token');
  const [localVolume] = useLocalStorage(`clientAudioVolume$${userEmail}`);
  const [showExpandTranscript, setShowExpandTranscript] = useState(false);

  useEffect(() => {
    if (call.url) {
      HowlerRef.current = new Howl({
        src: [
          // eslint-disable-next-line no-nested-ternary
          call.url
            ? call.url
            : call.filename
            ? `${API.getAudioPath}/${
                call.filename
              }?token=${`Bearer ${accessToken}`}`
            : '',
        ],
        volume: Number(localVolume) || 1,
      });
    }

    return () => {
      if (HowlerRef.current) {
        HowlerRef.current.unload();
      }
    };
  }, [call.url, HowlerRef, call.filename, accessToken, localVolume]);

  return (
    <div>
      <Player
        call={call}
        ref={HowlerRef}
        HowlerPlayId={HowlerPlayId}
        setIsPlaying={setIsPlaying}
        isPlaying={isPlaying}
        setShowExpandTranscript={setShowExpandTranscript}
        showExpandTranscript={showExpandTranscript}
        {...rest}
      />
      <EventsTab
        call={call}
        ref={HowlerRef}
        HowlerPlayId={HowlerPlayId}
        setIsPlaying={setIsPlaying}
        showExpandTranscript={showExpandTranscript}
        {...rest}
      />
    </div>
  );
}

SingleCallMedia.propTypes = {
  call: PropTypes.object,
  loading: PropTypes.bool,
  searchText: PropTypes.string,
  transcriptView: PropTypes.bool,
  toggleTranscriptView: PropTypes.func,
  handleReaction: PropTypes.func,
  dimensions: PropTypes.object,
  showMore: PropTypes.bool,
  setShowMore: PropTypes.func,
  setShowExtraMetaData: PropTypes.func,
  showExtraMetaData: PropTypes.bool,
};

export default SingleCallMedia;
