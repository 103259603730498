/**
 *
 * DatePicker
 *
 */

import React from 'react';
import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { DatePicker as CustomDatePicker } from 'antd';

function DatePicker() {
  return <div />;
}

DatePicker.propTypes = {};

export default CustomDatePicker;
