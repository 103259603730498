/*
 *
 * App actions
 *
 */

export default function action(type, payload = null) {
  if (payload !== null) {
    return {
      type,
      payload,
    };
  }
  return {
    type,
  };
}
