/**
 *
 * Pagination
 *
 */

import React from 'react';
import { Pagination as CustomPagination } from 'antd';

import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
function Pagination({ ...rest }) {
  return <CustomPagination showSizeChanger={false} {...rest} />;
}

Pagination.propTypes = {};

export default Pagination;
