import { useState, useCallback, useEffect } from 'react';

/**
 * https://stackoverflow.com/questions/68656311/custom-useelementsize-hook-doesnt-update-on-first-render
 * Gets the height and width of a div using the ref of the Element.
 * @param {*} elementRef takes Ref of the Element we want to watch over
 * @returns Updated width and weight of the element as an object
 */

const useElementSize = elementRef => {
  const [elementSize, setElementSize] = useState({
    width: 0,
    height: 0,
  });

  const listener = useCallback(
    () =>
      elementRef?.current &&
      setElementSize({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      }),
    [elementRef],
  );

  /**
   * Adds Event listener to get updates on the state
   */

  useEffect(() => {
    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);
  return elementSize;
};

export default useElementSize;
