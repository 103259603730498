/**
 *
 * Component: ProductTourDetails
 * Date: 30/8/2021
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';

import { EmptyState, Text } from 'components/common';

import styles from './style.css';

function ProductTourDetails({ title, description, embeddedVideo }) {
  const [readyPlayer, setReadyPlayer] = useState(true);
  if (window.Cypress) {
    window.readyPlayer = readyPlayer;
  }
  useEffect(() => {
    if (!readyPlayer) setReadyPlayer(true);
    const options = {
      id: embeddedVideo,
      width: 640,
    };

    const player = new Player(`vimeo-${embeddedVideo}`, options);
    player
      .ready()
      .then(() => {
        if (!readyPlayer) setReadyPlayer(true);
      })
      .catch(() => {
        setReadyPlayer(false);
      });

    return () => player.destroy();
  }, [embeddedVideo]);

  return (
    <div className={styles.container} data-testid="product-cy">
      <div className={styles.details}>
        <Text text={title} type="titleLarge" />
        <Text text={description} type="subtitleLarge" className="grey thin" />
      </div>
      <div className={styles.embeddedVideo}>
        <div
          data-testid="video-cy"
          id={`vimeo-${embeddedVideo}`}
          className={styles.embeddedVideoDiv}
        />
        {!readyPlayer && (
          <div>
            <EmptyState
              title="Video is not able to Load"
              message="Please contact support@prodigaltech.com for further information"
            />
          </div>
        )}
      </div>
    </div>
  );
}

ProductTourDetails.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  embeddedVideo: PropTypes.string,
};

export default ProductTourDetails;
