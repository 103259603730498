/**
 *
 * Component: NotFound
 * Date: 6/7/2020
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import EmptyState from '../EmptyState';
import style from './style.css';
function NotFound({ text }) {
  return (
    <div className={style.notFoundPage}>
      <EmptyState
        message={text || 'This page does not exist'}
        extra={
          <p>
            Go to{' '}
            <a href={`${window.location.origin}/search-results`}>Search Page</a>
          </p>
        }
      />
    </div>
  );
}

NotFound.propTypes = {
  text: PropTypes.string,
};

NotFound.propTypes = {};

export default NotFound;
