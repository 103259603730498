import {
  SET_HOVER_STATES,
  SET_SKIP_SILENCE,
  SET_TALK_METRICS,
  CREATE_SPANS,
  SET_SOURCE_URL,
  CREATE_AUDIO_BARS,
  CHANGE_PLAY_RATE,
  CHANGE_VOLUME,
} from './constants';

function audioReducer(state, action) {
  switch (action.type) {
    case SET_TALK_METRICS:
      return { ...state, ...action.payload };
    case CREATE_SPANS:
      return { ...state, ...action.payload };
    case SET_SOURCE_URL:
      return { ...state, ...action.payload };
    case CREATE_AUDIO_BARS:
      return { ...state, ...action.payload };
    case CHANGE_PLAY_RATE:
      return { ...state, ...action.payload };
    case CHANGE_VOLUME:
      return { ...state, ...action.payload };
    case SET_HOVER_STATES:
      return { ...state, ...action.payload };
    case SET_SKIP_SILENCE:
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

export default audioReducer;
