/**
 *
 * Component: ScorecardListColumn
 * Date: 8/6/2020
 *
 */

import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import SkeletonLoader from 'components/SkeletonLoader';

import style from './style.css';
import SortBy from '../SortBy';
import MultiSelectTree from '../MultiSelectTree';
import ReviewScorecardAction from '../ReviewScorecardAction';
function ScorecardListColumn({
  onChangeColumnTree,
  selectedColumnTree,
  handleChangeSortOrder,
  sortFieldList,
  selectAll,
  selectedRows,
  exportScorecards,
  totalCount,
  approveScorecards,
  scorecardTemplates,
  onChangeScorecardTemplate,
  scorecardTemplate,
  // initialFixedColumnTree,
  treeColumns,
  initialFixedColumn,
  sortList,
  enableActions = ['export', 'approve'],
  enableExport = ['excel', 'pdf'],
  loadingExport,
  singleSelectedTemplate = false,
  dimensionsTreeList,
  selectedDimensions,
  handleAggregationDimensionChange,
  loadingList,
}) {
  const [appliedFilters, setAppliedFilters] = useState();
  const [filterKeyId, setFilterKeyId] = useState('selectColumns');
  /**
   * Removes the applied filters.
   * */
  const handleClear = key => {
    if (appliedFilters[key]) {
      if (key === 'tag') {
        appliedFilters[key] = [];
      } else {
        appliedFilters[key] = [];
      }
      /**
       * Can be replaced by appliedFilters[key] = []; alone....
       * */
    }
    setAppliedFilters(appliedFilters);
  };

  /**
   * on Outside Click sets filterKeyId as selectColumns, if not else empty string.
   * */
  const handleClickOutside = () => {
    setFilterKeyId(filterKeyId === 'selectColumns' ? '' : 'selectColumns');
  };

  /**
   * orders scoreCardTemplate by disable key in asc.
   * */
  const scorecardTemplateDisableOrder = useMemo(
    () => _.orderBy(scorecardTemplates, ['disable'], ['asc']),
    [scorecardTemplates],
  );

  return (
    <div className={style.columnsActions}>
      <div className={style.gridColumn}>
        <SkeletonLoader type="filterButton">
          {!_.isEmpty(scorecardTemplates) && scorecardTemplate && (
            <MultiSelectTree
              handleClear={handleClear}
              handleClickOutside={handleClickOutside}
              showFilter
              single={singleSelectedTemplate}
              filterKeyId="selectTemplate"
              buttonName="Select Template"
              filterValues={scorecardTemplateDisableOrder}
              onFilterApplied={onChangeScorecardTemplate}
              selectedValues={scorecardTemplate}
            />
          )}
        </SkeletonLoader>
        {onChangeColumnTree && (
          <SkeletonLoader type="filterButton">
            {!_.isEmpty(scorecardTemplates) && !_.isEmpty(treeColumns) && (
              <MultiSelectTree
                handleClear={handleClear}
                handleClickOutside={handleClickOutside}
                showFilter
                filterKeyId="selectColumns"
                buttonName="Select Columns"
                filterValues={treeColumns}
                onFilterApplied={onChangeColumnTree}
                selectedValues={
                  !_.isEmpty(selectedColumnTree)
                    ? [...initialFixedColumn, ...selectedColumnTree]
                    : initialFixedColumn
                }
              />
            )}
          </SkeletonLoader>
        )}
        {handleAggregationDimensionChange && (
          <SkeletonLoader type="filterButton">
            {!_.isEmpty(scorecardTemplates) &&
              !_.isEmpty(dimensionsTreeList) && (
                <MultiSelectTree
                  handleClear={handleClear}
                  handleClickOutside={handleClickOutside}
                  showFilter
                  single
                  filterKeyId="selectDimensions"
                  buttonName="Aggregated by"
                  filterValues={dimensionsTreeList}
                  onFilterApplied={handleAggregationDimensionChange}
                  selectedValues={[selectedDimensions]}
                />
              )}
          </SkeletonLoader>
        )}
      </div>
      <div className={style.rightAlignActionBUttons}>
        {!_.isEmpty(scorecardTemplates) && (
          <SortBy
            showFilterKey={filterKeyId}
            key={sortList}
            sortList={sortList}
            showFilter={filterKeyId}
            onSortClicked={e => setFilterKeyId(e)}
            onClickOutside={() => setFilterKeyId('')}
            onSortApplied={handleChangeSortOrder}
            selectedSort={sortFieldList}
            loading={loadingList}
          />
        )}
        <SkeletonLoader repeat={2} type="button">
          {!_.isEmpty(scorecardTemplates) && (
            <ReviewScorecardAction
              selectAll={selectAll}
              selectedRows={selectedRows}
              exportScorecards={exportScorecards}
              totalCount={totalCount}
              approveScorecards={approveScorecards}
              enableActions={enableActions}
              enableExport={enableExport}
              loadingExport={loadingExport}
            />
          )}
        </SkeletonLoader>
      </div>
    </div>
  );
}

ScorecardListColumn.propTypes = {
  onChangeColumnTree: PropTypes.func,
  handleChangeSortOrder: PropTypes.func,
  selectedColumnTree: PropTypes.array,
  sortFieldList: PropTypes.array,
  selectAll: PropTypes.bool,
  selectedRows: PropTypes.array,
  exportScorecards: PropTypes.func,
  totalCount: PropTypes.number,
  approveScorecards: PropTypes.func,
  scorecardTemplates: PropTypes.array,
  onChangeScorecardTemplate: PropTypes.func,
  scorecardTemplate: PropTypes.array,
  loadingList: PropTypes.bool,
  initialFixedColumn: PropTypes.array,
  sortList: PropTypes.array,
  enableActions: PropTypes.array,
  enableExport: PropTypes.array,
  loadingExport: PropTypes.bool,
  treeColumns: PropTypes.array,
  singleSelectedTemplate: PropTypes.bool,
  dimensionsTreeList: PropTypes.array,
  selectedDimensions: PropTypes.string,
  handleAggregationDimensionChange: PropTypes.func,
};

export default ScorecardListColumn;
