/**
 *
 * Switch
 *
 */

import React from 'react';
import { Switch as CustomSwitch } from 'antd';

import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
function Switch({ ...rest }) {
  return <CustomSwitch {...rest} />;
}

Switch.propTypes = {};

export default Switch;
