/**
 *
 * Callback
 *
 */

import React from 'react';

import LoadingIndicator from '../LoadingIndicator';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

/**
 * Callback Function to render a Loading Indicator
 */
function Callback() {
  return (
    <div style={{ width: '100vw' }}>
      <LoadingIndicator text="Logging in" />
    </div>
  );
}

Callback.propTypes = {};

export default Callback;
