/**
 *
 * Component: HowlerPlayer
 * Date: 4/4/2022
 *
 */

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useCallback, forwardRef } from 'react';

const HowlerPlayer = forwardRef(
  ({ setAudioCurrentTime, setIsHowlerLoading }, HowlerRef) => {
    const requestRef = useRef();
    const previousTimeRef = useRef();

    const animate = useCallback(
      time => {
        if (previousTimeRef.current !== undefined) {
          // Pass on a function to the setter of the state
          // to make sure we always have the latest state
          setIsHowlerLoading(HowlerRef.current?.state() !== 'loaded');
          if (
            HowlerRef.current?.playing()
            // && HowlerRef.current?.state() !== 'loaded'
          ) {
            setAudioCurrentTime(HowlerRef.current?.seek());
          }
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
      },
      [HowlerRef, setAudioCurrentTime, setIsHowlerLoading],
    );
    useEffect(() => {
      requestRef.current = requestAnimationFrame(animate);
      return () => cancelAnimationFrame(requestRef.current);
    }, [animate]);

    return <></>;
  },
);

export default HowlerPlayer;

HowlerPlayer.propTypes = {
  setAudioCurrentTime: PropTypes.func,
  setIsHowlerLoading: PropTypes.func,
};
