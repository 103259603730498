/**
 *
 * Col
 *
 */

import React from 'react';
import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Col as CustomCol } from 'antd';
function Col({ ...rest }) {
  return <CustomCol {...rest} />;
}

Col.propTypes = {};

export default Col;
