/**
 *
 * InputFilter
 *
 */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
// import { debounce } from 'lodash';

import mixpanel from 'utils/mixpanel';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import { Icon, Text, Input } from 'components/common';

import styles from './style.css';
import RichInput from '../RichInput';

function InputFilter({
  filter,
  advance,
  hasError,
  selectedValues = '',
  onFilterApplied,
  handleRemoveAdvanceFilter,
}) {
  /**
   * trims out array elements and joins them together with commas.
   */
  const getInputValue = useCallback(
    list =>
      advance && Array.isArray(list)
        ? list
            .filter(e => e.trim())
            .map(e => e.trim())
            .join(',')
        : list,
    [advance],
  );

  const [inputText, setInputText] = useState(getInputValue(selectedValues));
  if (window.Cypress) {
    window.inputText = inputText;
    window.getInputValue = getInputValue;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(
    // debounce(value => onInputChange(value), 200),
    value => onInputChange(value),
    [],
  );

  const inputRef = useRef();

  useEffect(() => {
    const newVal = getInputValue(selectedValues);
    setInputText(newVal);
  }, [selectedValues, getInputValue]);

  useEffect(() => {
    if (hasError?.length)
      inputRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }, [hasError]);

  /**
   * * Split to make array -> filter empty strings -> remove white-spaces in list elements
   */
  const onInputChange = value => {
    onFilterApplied(
      filter.key,
      advance
        ? value
            .split(',')
            .filter(e => e.trim())
            .map(e => e.trim())
        : value,
    );
  };

  return (
    <div className={styles.container}>
      <Text type="subtitle" text={filter.name} />

      <div
        className={styles.inputFilterBox}
        ref={inputRef}
        data-testid="input-cy"
      >
        {advance ? (
          <div className={styles.advanceInputBox}>
            <RichInput
              data-testid="input1-cy"
              hasError={hasError}
              placeholder="Enter a comma separated list"
              defaultValue={inputText}
              onChange={debouncedCallback}
              onBlur={onInputChange}
              backgroundColor="#0215290c"
              height={43}
            />
          </div>
        ) : (
          <Input
            className="input-box"
            value={inputText}
            placeholder="Will search exact match only"
            onChange={onInputChange}
            onBlur={onInputChange}
          />
        )}
        {advance && (
          <Icon
            data-testid="delete-cy"
            size={12}
            className="cursor-pointer"
            type="delete"
            onClick={() => {
              handleRemoveAdvanceFilter(filter.field);
              mixpanel('Advance Filters Removed', {
                [mixpanelKeys.advanceFilter]: filter,
              });
            }}
          />
        )}
      </div>
      {hasError?.length ? (
        <div className="advance-input-filter-error" data-testid="error-cy">
          <Text
            type="subtitle"
            className="ptp-red"
            text="The entered input appears to be invalid"
          />
        </div>
      ) : null}
    </div>
  );
}

InputFilter.propTypes = {
  filter: PropTypes.object,
  advance: PropTypes.bool,
  hasError: PropTypes.array,
  selectedValues: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onFilterApplied: PropTypes.func,
  handleRemoveAdvanceFilter: PropTypes.func,
};

export default InputFilter;
